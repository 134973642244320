var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.edit ? _vm.$t("Editar Grupo") : _vm.$t("Novo Grupo"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: { save: _vm.saveGroup, hidden: _vm.hideSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "FormulateForm",
                      { ref: "groupForm", attrs: { name: "form" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required input-uppercase",
                          attrs: {
                            id: "product-group-name",
                            type: "text",
                            label: _vm.$t("Nome"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.localGroup.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.localGroup, "name", $$v)
                            },
                            expression: "localGroup.name",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-wrap justify-content-between",
                          },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "product-group-hectoliters",
                                type: "switch",
                                label: _vm.$t("Contabilizar hectolitros"),
                                instruction: _vm.$t(
                                  "Quando habilitado, os produtos deste grupo terão um atributo que indica a quantidade de mililitros da unidade, para que se possa fazer a contabilização de hectolitros comercializados."
                                ),
                              },
                              model: {
                                value: _vm.localGroup.hectoliters,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localGroup, "hectoliters", $$v)
                                },
                                expression: "localGroup.hectoliters",
                              },
                            }),
                            _c("FormulateInput", {
                              staticClass: "flex-grow-1 ml-2",
                              attrs: {
                                id: "product-group-is-chopp",
                                type: "switch",
                                label: _vm.$t("É chope?"),
                                instruction: _vm.$t(
                                  "Quando habilitado, indica que os produtos deste grupo se tratam de chope líquido."
                                ),
                              },
                              model: {
                                value: _vm.localGroup.isChopp,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localGroup, "isChopp", $$v)
                                },
                                expression: "localGroup.isChopp",
                              },
                            }),
                            _c("FormulateInput", {
                              attrs: {
                                id: "product-group-offers-cold-option",
                                type: "switch",
                                label: _vm.$t("Opção de produto gelado"),
                                instruction: _vm.$t(
                                  "Quando habilitado, os produtos deste grupo oferecerão opção de produto gelado nos pedidos do app/site."
                                ),
                              },
                              model: {
                                value: _vm.localGroup.offersColdOption,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.localGroup,
                                    "offersColdOption",
                                    $$v
                                  )
                                },
                                expression: "localGroup.offersColdOption",
                              },
                            }),
                            _c("FormulateInput", {
                              staticClass: "flex-grow-1",
                              attrs: {
                                id: "product-group-display-order",
                                type: "number",
                                label: _vm.$t("Ordenação"),
                                validation: "min:1",
                                instruction: _vm.$t(
                                  "Indica a ordenação que será utilizado no App/Site."
                                ),
                              },
                              model: {
                                value: _vm.localGroup.displayOrder,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localGroup, "displayOrder", $$v)
                                },
                                expression: "localGroup.displayOrder",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("FormulateInput", {
                      attrs: {
                        id: "product-group-image-local",
                        type: "uploader",
                        deletable: "true",
                        "max-files": "1",
                        meta: false,
                        label: _vm.$t("Imagem"),
                      },
                      model: {
                        value: _vm.imageLocal,
                        callback: function ($$v) {
                          _vm.imageLocal = $$v
                        },
                        expression: "imageLocal",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }